<template>
    <div class="list-box">
        <i-header :title="titleArr[$route.query.type]" @back-event="toBack"></i-header>
        <i-list class="cnt-box" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <i-tips :tipsList="tipsList"></i-tips>
            <div class="count">已邀请{{ parseInt(count) }}人</div>

            <template v-if="docList.length">
                <div class="item-box" v-for="(item, index) in docList" :key="index">
                    <div class="left">
                        <template v-if="$route.query.type == 'Attendee'">
                            <div class="name">{{ item.hospitalName }}</div>
                            <div class="desc">{{ item.department }}</div>
                            <div class="desc">参会人数： {{ parseInt(item.participantsNumber) }}人</div>
                        </template>

                        <template v-if="$route.query.type == 'Speaker'">
                            <div class="name">{{ item.speakerName }}</div>
                            <div class="desc">{{ item.hospitalName }}</div>
                            <div class="desc">{{ item.department }}</div>
                            <div class="desc">邮箱：{{ item.speakerEmail }}</div>
                        </template>
                    </div>
                    <div v-if="!onlyRead" class="right">
                        <i-icon name="icon-edit" @click.native="toEdit(item, index)"></i-icon>
                        <i-icon name="icon-delete" @click.native="toDel(item, index)"></i-icon>
                    </div>
                </div>
            </template>
        </i-list>
        <i-skeleton v-if="isFirst" title :row="8" />
        <empty-box v-if="!docList.length && !isFirst && !loading" />
        <i-add-button v-if="!onlyRead" :optionsList="btnOptionList" style="z-index:3" @click="addDoc" />
    </div>
</template>

<script>
import emptyBox from "@/components/iEmpty";

export default {
    name: "FuncList",
    components: {
        emptyBox
    },
    data() {
        return {
            titleArr: {
                Attendee: "参会人",
                Speaker: "讲者",
            },
            onlyRead: false,
            showMoreTips: false,
            pageIndex: 1,
            pageSize: 10,
            docList: [],
            total: 0,
            isFirst: true,
            loading: false,
            finished: false,
            showEditBox: false,
            tipsCode: {
                Attendee: "ievent-add-attendee",
                Speaker: "ievent-add-speaker",
            },
            tipsList: [],
            count: 0,
            btnOptionList: [],
        };
    },
    created() {
        // 初始化
        this.isOnlyRead();
        this.getTips();
        this.getList();
    },
    methods: {
        toBack() {
            this.$router.back();
        },
        getList(clear) {
            let params = {
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
                Filter: {
                    IsDeleted: false,
                    Catalog: this.$route.query.type,
                    MiceId: this.$route.query.miceId,
                },
                Projection: {},
                Sort: {},
            };
            this.loading = true;
            this.$service.QueryDoc(params).then((res) => {
                this.loading = false;
                if (res && res.success) {
                    this.isFirst = false;
                    if (res.success) {
                        if (clear == true) {
                            this.docList = [];
                        }
                        this.docList = this.docList.concat(res.content.rows);
                        switch (this.$route.query.type) {
                            case "Attendee":
                                this.docList.map((val) => {
                                    this.count += Number(val.participantsNumber);
                                });
                                break;
                            case "Speaker":
                                this.count = res.content.total;
                                break;
                            default:
                                break;
                        }
                        this.total = res.content.total;
                    }
                }
            });
        },
        addDoc() {
            this.$router.push({
                name: "funcAdd",
                query: this.$route.query,
            });
        },
        onLoad() {
            if (this.pageSize * this.pageIndex >= this.total) {
                this.finished = true;
            } else {
                this.pageIndex += 1;
                this.getList();
            }
        },
        toEdit(item) {
            // 编辑的数据放入store
            this.$store.commit("setValue", {
                key: "funcItemInfo",
                value: item,
            });
            this.$router.push({
                name: "funcAdd",
                query: Object.assign(this.$route.query, {isEdit: 1}),
            });
        },
        toDel(item, index) {
            let params = {
                Updater: item,
                Query: {
                    DocId: item.DocId,
                },
            };
            this.$idialog
                .confirm({
                    title: "提示",
                    message: "是否确认删除？",
                })
                .then(() => {
                    // 当前组件中表单添加和修改不跳转路由
                    if (this.confirmEntireList) {
                        this.docList.splice(index, 1);
                    } else {
                        this.$service.DeleteDoc(params).then((res) => {
                            if (res && res.success) {
                                this.$itoast.success("删除成功！");
                                this.docList.splice(index, 1);
                            }
                        });
                    }
                })
                .catch(console.log);
        },
        async getTips() {
            let params = {
                collection: "cfg-crossevent-noticebar",
                useCaching: false,
                cachingKey: "",
                filter: {
                    TenantCode: "crossevent",
                    DirectoryId: this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id"),
                    EntryCode: this.tipsCode[this.$route.query.type],
                },
                projection: {
                    _id: 0,
                },
            };
            let res = await this.$service.GetSettings(params);
            if (res && res.success && res.content) {
                this.tipsList = res.content.content;
            }
        },
        isOnlyRead() {
            let miceStatus = this.$smartStorage.get("miceInfo").Status;
            let onlyReadStatusList = [-1, 20, 80, 100];
            if (onlyReadStatusList.includes(miceStatus)) {
                this.onlyRead = true;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.list-box {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    /deep/.van-sticky {
        background-color: #ffffff !important;
    }
    .innerComBox {
        position: fixed;
        z-index: 3;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: white;
    }
    .cnt-box {
        // height: calc(100vh - 109px);
        padding: 0 0.25rem;
        box-sizing: border-box;

        .count {
            font-size: 0.12rem;
            color: #909398;
            margin-top: 0.2rem;
        }
        .item-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 0.27rem;
            .left {
                .name {
                    font-size: 0.16rem;
                    color: #13161B;
                    margin-bottom: 0.1rem;
                }
                .desc {
                    font-size: 0.12rem;
                    color: #909398;
                }
            }
            .right {
                display: flex;
                svg {
                    width: 0.2rem;
                    height: 0.2rem;
                    display: block;
                }
                :nth-child(2) {
                    margin-left: 0.18rem;
                }
            }
        }
    }
}
</style>
